import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import "./about.css";
import Index from './Index';
import Vision from './Vision';
import Query from '../../Components/Query/Query';
import Newsletter from '../../Components/Newsletter/Newsletter';
import Banner from './Banner';



const About = () => {
  return (
    <div>
        <Navbar />
        <Banner />
        <Index />
        <Vision />
     
        <Newsletter />
        <Footer />
    </div>
  )
}

export default About