import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import "./landingpage.css";
import LandingFOrm from './LandingFOrm';
import computer from "../../assets/img/landing/computer.png"
import MyFormComponent from './MyFormComponent';
import { Link } from 'react-router-dom';
import Logo from "../../assets/img/logo.png"
import client from "../../sanity"
import Adsection from './Adsection';
import Stripad from './Stripad';
import Landingnavbar from './Landingnavbar';
const LandingPage = () => {


  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [image, setImage] = useState();
  const [imagemobile, setImagemobile] = useState();
  const [size, setSize] = useState(window.innerWidth)

  useEffect(() => {
    const fetchData = async () => {
      const query = `*[_type == "contentlanding"] | order(index asc) {
        ...,
        "mainImageUrl": mainImage.asset->url
      }`;
      try {
        const result = await client.fetch(query);
        setData(result);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchData2 = async () => {
      const query = '*[_type == "landingmain"]{..., "mobilebackImageUrl": mobilebackImage.asset->url}{..., "backImageUrl": backImage.asset->url}';
      try {
        const result = await client.fetch(query);
        setData2(result);
        setImage(result[0].backImageUrl)
        setImagemobile(result[0].mobilebackImageUrl)
        console.log(result)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData2();
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => setSize(window.innerWidth);

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  console.log(size)

  const imageSrc = size < 767 ? imagemobile : image;

  return (
    <>
      <Landingnavbar />
      <div className='landingpageback' style=
        {{
          backgroundImage: "url(" + imageSrc + ")"
        }}
      >
        <div className='container'>

          <div className='row pt-5'>
            <div className='col-lg-7 col-md-6'>
              {
                data2.map((item) => (
                  <div className='landing-main'>
                    <h2>
                      {item.mainheading}
                    </h2>

                    <span>
                      {item.subheading}
                    </span>

                    <p>

                      {item.body.map((bodyItem) => (
                        <p key={bodyItem._key}>
                          {bodyItem.children.map((child) => (
                            <>
                              {child.text.replace(/<\/?[^>]+(>|$)/g, '')}
                            </>
                          ))}
                        </p>
                      ))}
                    </p>
                  </div>
                ))
              }
            </div>

            <div className='col-lg-5 col-md-6'>
              <MyFormComponent />
            </div>
          </div>


        </div>
      </div>

      <div className=''>
        <Stripad />
      </div>

      <section className='landing_services container'>
            <div className='row text-center mb-5'>
              <h2 className='fw-bold'>Our services </h2>
              <p>AccidentMATE connects personal injury victims with top-notch lawyers and medical professionals to ensure you receive the best care and maximum compensation!</p>
            </div>

            <div className='row'>
              <div className='col-lg-4'>
                <div className='landing-box-service'>
                <h5>1.	Motor Vehicle Accidents:</h5>
                <ul>

                  <li>Car Accidents</li>
                  <li>Motorcycle Accidents</li>
                  <li>Truck Accidents</li>
                  <li>Bicycle Accidents</li>
                  <li>Pedestrian Accidents</li>

                </ul>
                </div>
              </div>

              <div className='col-lg-4'>
                <div className='landing-box-service'>
                <h5>2. Workplace Injuries:</h5>
                <ul>

                  <li>Construction Site Injuries</li>
                  <li>Industrial Accidents</li>
                  <li>Repetitive Strain Injuries</li>
                  <li>Occupational Diseases</li>
                </ul>
                </div>
              </div>


              <div className='col-lg-4'>
                <div className='landing-box-service'>
                <h5>3.	Premises Liability</h5>
                <ul>

                  <li>Slip and Fall Accidents</li>
                  <li>Inadequate Security</li>
                  <li>Elevator and Escalator Accidents</li>
                  <li>Swimming Pool Accidents</li>
           

                </ul>
                </div>
              </div>


              <div className='col-lg-4'>
                <div className='landing-box-service'>
                <h5>4.	Product Liability:</h5>
                <ul>

                  <li>Defective Medical Devices</li>
                  <li>Faulty Automotive Parts</li>
                  <li>Contaminated Food Products</li>
                  <li>Dangerous Children's Toys</li>
                 

                </ul>
                </div>
              </div>


              <div className='col-lg-4'>
                <div className='landing-box-service'>
                <h5>5.	Environmental Injuries:</h5>
                <ul>

                  <li>Asbestos Exposure</li>
                  <li>Toxic Mold</li>
                  <li>Chemical Spills</li>
                  <li>Air and Water Pollution</li>
                

                </ul>
                </div>
              </div>

              <div className='col-lg-4'>
                <div className='landing-box-service'>
                <h5>6.	Animal Attacks:</h5>
                <ul>

                  <li>Dog Bites</li>
                  <li>Exotic Animal Attacks</li>
            
                </ul>
                </div>
              </div>


              <div className='col-lg-4'>
                <div className='landing-box-service'>
                <h5>7.	Nursing Home Abuse and Neglect:</h5>
                <ul>

                  <li>Physical Abuse</li>
                  <li>Emotional Abuse</li>
                  <li>Financial Exploitation</li>
                

                </ul>
                </div>
              </div>

              <div className='col-lg-4'>
                <div className='landing-box-service'>
                <h5>8.	Aviation and Maritime Accidents:</h5>
                <ul>

                  <li>Airplane Crashes</li>
                  <li>Boating Accidents</li>
                  <li>Cruise Ship Injuries</li>


                </ul>
                </div>
              </div>


              <div className='col-lg-4'>
                <div className='landing-box-service'>
                <h5>9.	Sports and Recreation Injuries:</h5>
                <ul>
                  <li>Gym Accidents</li>
                  <li>Amusement Park Injuries</li>
                  <li>Skiing and Snowboarding Accidents</li>
                </ul>
                </div>
              </div>
              
            </div>
          </section>


      <div className='landingpagecontent'>
        <div className='container'>

          <div className='row mt-3'>
            <div className='col-lg-12 col-sm-12 commondisplayflex'>
              <h4 className='text-center help-section'>We are here to help!</h4>
            </div>
          </div>

          <div className='row'>
            {data.map((item) => (
              <div key={item._id} className='col-lg-6 col-md-6 col-sm-12'>
                <div className="content">
                  <img className='content-icon' src={item.mainImageUrl} ></img>
                  <h4>
                    {item.title}
                  </h4>
                  <span>
                    {item.body[0].children[0].text}
                  </span>
                </div>
              </div>
            ))}
          </div>

        
          <Adsection />

          <div className='row'>
            <Link className='btn' to="/">Visit Website</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default LandingPage
