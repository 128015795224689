import React from 'react'
import { Link } from 'react-router-dom'
const Getintouch = () => {
  return (
    <div>
      <section id="main-services" className="main-services">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-md-4 mb-4">
              <h2 className="fw-semibold mb-3">Get in Touch</h2>
              <div className="info ">
                <div className="email x" >
                  <div className=" flexdisplay" >
                    <i className="fa fa-envelope contact_icon" />
                    <p className="text-dark "> Email:</p>
                    <Link className="text-dark contact_link" to="mailto:info@accidentmate.com">accidenthelp@accidentmate.com</Link>
                  </div>
                </div>
                <br />
                <div className="phone flexdisplay">
                  <i className="fa fa-phone" />
                  <p className="text-dark">Call:</p>
                  <a className="text-dark contact_link" href="tel:+18889821882">+1-888-982-1882</a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 mb-4" />
            <div className="col-lg-3 col-md-4 mb-4">
              <div className="member bg-dblue text-center text-white h-100" data-aos="zoom-in" data-aos-delay={100}>
                <div className="member-info">
                  <h3 className="text-white fw-bold">Emergency?</h3>
                  <h3 className="text-white fw-semibold">Call Us</h3>

                  <h4 className="text-white fw-bold">+1-888-982-1882</h4>
                  <p className="text-white">(Toll Free)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Getintouch