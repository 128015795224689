import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import client from "../../sanity"
const Adsection = () => {

    const [data, setData] = useState()
    const [data2, setData2] = useState()
    const [size, setSize] = useState(window.innerWidth)
    useEffect(() => {
        const fetchData = async () => {
            const query = '*[_type == "offerimage"]{..., "imagepcurl": imagepc.asset->url}{..., "imagephoneurl": imagephone.asset->url}';
            try {
                const result = await client.fetch(query);
                    setData2(result[0].imagephoneurl);
                    setData(result[0].imagepcurl);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [])

    useEffect(() => {
        const handleResize = () => setSize(window.innerWidth);
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    console.log(size)


    const imageSrc = size < 767 ? data2 : data;

    return <img className="mt-4 mb-3 img-fluid w-100" src={imageSrc}  />;
}

export default Adsection
