import React from "react";
import "./Modal.css";
import image from "../../assets/img/man.png"
import { useState, useEffect, useRef } from "react";
import { Icon } from '@iconify/react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import image2 from "../../assets/img/popimage.jpg"
import emailjs from '@emailjs/browser';
import client from "../../sanity"
function Modal({ setOpenModal }) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const form = useRef();
  const [emailError, setEmailError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [nameError, setNameError] = useState('');

  const [data, setData] = useState([]);

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = '100%';
    }

    
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const query = '*[_type == "popupform"]';
      try {
        const result = await client.fetch(query);
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  console.log("modal data", data)

  const handleNameChange = (event) => {
    const value = event.target.value;
    setName(value);

    if (value.trim().length < 3) {
      setNameError('Name must be atleast 3 characters.');
      event.target.setCustomValidity('');
    } else {
      setNameError('');
      event.target.setCustomValidity('');
    }
  };

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    setPhone(value);

    const regex = /^\+\d{8,}$/;
    if (!regex.test(value)) {
      setPhoneNumberError('Please enter a valid phone number.');

    } else {
      setPhoneNumberError('');
    }
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);

    const regex = /^\S+@\S+\.\S+$/; // Regular expression to match a valid email address format
    if (!regex.test(value)) {
      setEmailError('Please enter a valid email address.');
      event.target.setCustomValidity('')
    } else {
      setEmailError('');
      event.target.setCustomValidity('')
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior

    // Check if there are any errors
    if (nameError || phoneNumberError || emailError) {
      // Handle the case when there are validation errors (e.g., display an error message)
      console.log('Form has errors. Please fix them.');
      return;
    }

    if (phone.length < 8) {
      // Custom validation error handling when phone number is not valid
      setPhoneNumberError('Enter Valid phone number');
      return;
    }




    emailjs.sendForm('service_6cuir0f', 'hover-form', form.current,'4Y94BulGaL_N4yMfK')
      .then((result) => {
        setTimeout(function () {
          window.location.assign('/thankyou');
        }, 2000);
      }, (error) => {
          alert(error.text);
      });



  };

  return (
    <div className="modalBackground">
      <div className="modalContainer">


        <div className="body">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-0  modal-row1">
                <div className="popindetails">





                </div>
              </div>
              <div className="col-md-6 modal-row2">

               {
                data.map((item)=>(
                  <>
                   <h4>{item.mainheading}</h4>
                <small style={{color:"black"}}>{item.subheading}</small>
                <br /> <br />
                <span className="outerSpan"> 
                {item.content.map((contentItem) => (
            <p key={contentItem._key}>
              {contentItem.children[0].text.replace(/<\/?[^>]+(>|$)/g, '')}
            </p>
          ))}
                </span>
</>
                ))
               }
                <form ref={form} onSubmit={handleSubmit} className="container mt-4">
                 
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={handleEmailChange}
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
                      onInput={handleEmailChange}
                    />
                    {emailError && <p className="form-error">{emailError}</p>}

                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="phone">Phone Number:</label>

                    <PhoneInput
                      containerStyle={{ width: '100%' }}
                      inputStyle={{ width: '100%' }}
                      country={"us"}
                      inputClass="custom-input-class"
                      inputProps={{
                        name: 'phone',
                        required: true,

                      }}
                      onChange={phone => setPhone(phone)}
                      value={phone}
                    />
                    {phoneNumberError && <p className="form-error">{phoneNumberError}</p>}

              

                

                  </div>

                 
                  <button type="submit" className="modal-row2-button">Submit</button>
                </form>

              </div>
            </div>
          </div>

          <button className="modal-close-button" onClick={() => { setOpenModal(false) }}>
            <Icon icon="iconamoon:close-thin" color="#008bd0" width="36" height="36" />
          </button>
        </div>

      </div>

    </div>
  );
}

export default Modal;
