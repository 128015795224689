import React from 'react'
import client from "../../../sanity"
import { Icon } from '@iconify/react';

import mobile_image from "../../../assets/img/mobile-demo2.png"
import { useEffect } from 'react';
import { useState } from 'react';
const About = () => {
  const [data, setData]= useState([])

  useEffect(()=>{
    const fetchdata = async () =>{
      const query = '*[_type == "mainpageabout"]{..., "mainImageUrl": mainImage.asset->url}';
        try{
          const result = await client.fetch(query);
          setData(result)
        }
        catch(error){
            console.log(error)
        }
    }

    fetchdata();
  },[])

  console.log(data)

  return (
    <div>
        <section id="about" className="about mb-sm-3  mt-sm-3">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 icon-boxes d-flex flex-column justify-content-center py-5" data-aos="fade-left">
            <h1 className="fw-bold fs-3 word-new-text">How are we helping accident victims?</h1>
            <div className="row">

           {
            data.map((item)=>(
              <div className="col-lg-6 col-md-6">
              <div className="icon-box d-flex flex-column align-items-center helping-box" data-aos="zoom-in" data-aos-delay="100">
                <div className=""><img style={{width:"48px"}} src={item.mainImageUrl}></img></div>
                <h5 className="title ">{item.heading}</h5>
                <p className="description">{item.subheading}</p>
              </div>
            </div>
            ))
           }

           
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 video-box d-flex justify-content-center align-items-center" data-aos="fade-right">
            <img src={mobile_image} className="img-fluid " alt="mobile_image" />
          </div>
        </div>

      </div>
    </section>
    </div>
  )
}

export default About