import React, { useState, useRef } from 'react';
import "./query.css";
import Phone_icon from "../../assets/img/queryicon.png";
import { Icon } from '@iconify/react';
import emailjs from '@emailjs/browser'; // Changed import for emailjs library
import Avatar_image from "../../assets/img/man.png";
import Modall from "../../Components/Modall/Modall";
import Modallerror from "../../Components/Modall/Modallerror";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Query = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const initialValues = {
    name: '',
    phone: '',
    email: '',
    message: '',
  };

  const formRef = useRef();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Name must be at least 3 characters')
      .required('Name is required'),
    phone: Yup.string()
      .min(10, 'Phone number must be at least 10 digits')
      .required('Phone number is required'),
    email: Yup.string()
      .email('Invalid email address')
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email address')
      .required('Email is required'),
    message: Yup.string().required('Message is required'),
  });

  const closeModal = () => {
    setShowModal(false);
    setShowModalError(false);
  };

  const sendEmail = (values, actions) => {

    
    const blacklistedDomains =

    [
      '10minutemail.com', 'guerrillamail.com', 'mailinator.com', 'tempmail.org', 'throwawaymail.com', 'temp-mail.org', 
      'sharklasers.com', 'getairmail.com', 'yopmail.com', 'dispostable.com', 'jetable.org', 'mintemail.com', 
      'trashmail.com', 'fakeinbox.com', 'maildrop.cc', 'mailnesia.com', 'emailondeck.com', 'discard.email', 
      'anonbox.net', 'mailcatch.com', 'tempmailaddress.com', 'tempail.com', 'tempinbox.com', 'inboxalias.com', 
      'fake-email.cc', 'mailsac.com', 'mailnesia.org', 'mytemp.email', 'mailforspam.com', 'zippymail.info', 
      'getnada.com', 'mailnesia.info', 'emlhub.com', 'disposableinbox.com', 'moakt.com', 'mail2tor.com', 'inboxkitten.com', 
      'mintemail.net', 'yopmail.fr', 'incognitomail.org', 'getnada.net', 'fakeinbox.org', 'getnada.com', 'yopmail.net', 
      'mailinator2.com', '10mail.org', 'yopmail.org', 'mailinator.net', 'trashmailer.com', 'incognitomail.net', 
      'byebyemail.com', '10minutemail.net', 'givmail.com', 'incognitomail.com', 'byebyemail.net', 'get1mail.com', 
      '10minutemail.be', 'eml.pp.ua', 'mailinator.org', 'zhorachu.com', 'mymail-in.net', 'mailinator.in', 'spamavert.com', 
      'mytempmail.com', 'mytemp.email', 'meltmail.com', 'tempmail.de', 'tempmail.it', 'tempmail.fr', 'tempmail.ru', 
      'spamfree24.org', 'mailnesia.com', 'spamgourmet.com', 'tempmail2.net', 'mailcatch.com', 'spamex.com', 
      'tempomail.fr', 'jetable.fr.nf', 'incognitomail.com', 'tempinbox.co.uk', 'guerrillamailblock.com', 
      'spambox.us', 'wh4f.org', 'mailnesia.org', 'mohmal.com', 'spam4.me', 'deadaddress.com', 'tempemail.net', 
      'my10minutemail.com', 'spambox.me', 'tempomail.com', 'spamoff.de', 'spaml.de', 'get2mail.fr', 'trbvm.com', 
      'disposableinbox.com', 'mailinator.fr', 'incognitomail.net', 'tmpmail.net', 'guerrillamail.biz', 'binkmail.com', 
      'incognitomail.org', 'grr.la', 'mailinator.co.uk'
  ]

    const email = formRef.current["email"].value;
    const domain = email.split('@')[1].toLowerCase();
  
    // Check if the domain is blacklisted
    if (blacklistedDomains.includes(domain)) {
      // Show an error message for blacklisted domain
      setShowModalError(false);
      alert("Domain blocked.")
      setTimeout(function () {
        setShowModalError(false);
      }, 1000);
      return;
    }
  

    emailjs.sendForm('service_6cuir0f', 'hover-form', formRef.current, '4Y94BulGaL_N4yMfK')
      .then(
        (result) => {
          setTimeout(function () {
            window.location.assign('/thankyou');
          }, 500);
          console.log(result)
      }, (error) => {
        setShowModalError(true);
        console.log(error)
      });
  };

  function myFunction() {
    document.getElementById("deployform").classList.add('open');
  }

  function myFunction1() {
    document.getElementById("deployform").classList.remove('open');
  }

  return (
    <div>
      {showModal && <Modall closeModal={closeModal} />}
      {showModalError && <Modallerror closeModal={closeModal} />}

      <div></div>
      <section id="query">
        <div id="deployform" className="deployform">
          <img src={Phone_icon} alt="Phone_icon" className="icon" onClick={myFunction} />
          <i className="fa fa-times cancel" onClick={myFunction1} aria-hidden="true"></i>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={sendEmail}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form ref={formRef}>
                <span> <img src={Avatar_image} />Hi, There</span>
                <Field
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="input-field"
                />
                <ErrorMessage name="name" component="div" className="text-danger text-center" />

                <PhoneInput
                  className="input-field newPhone"
                  country={'us'}
                  value={values.phone}
                  inputProps={{
                    name: 'phone',
                    required: true,
                  }}
                  onChange={(value) => setFieldValue('phone', value)}
                />
                <ErrorMessage name="phone" component="div" className="text-danger text-center" />

                <Field
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="input-field"
                />
                <ErrorMessage name="email" component="div" className="text-danger text-center" />

                <Field
                  as="textarea"
                  name="message"
                  placeholder="Your message"
                  className="input-field"
                />
                <ErrorMessage name="message" component="div" className="text-danger text-center" />

                <button type="submit" className="btn1" >
                  Talk to us today
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </div>
  );
};

export default Query;
