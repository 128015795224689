import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Modall from '../../../Components/Modall/Modall';
import Modallerror from '../../../Components/Modall/Modallerror';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const[verified, setVerified] = useState(false);

  const form1 = useRef();
  const recaptchaRef = useRef();
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModalError(false);
  };

  const validationSchema = Yup.object({
    name: Yup.string().min(3, 'Must be at least 3 characters').required('Required'),
    phone: Yup.string().min(10, 'Must be at least 10 characters').required('Required'),
    email: Yup.string().email('Invalid email address') .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      'Invalid email address'
    ).required('Required'),
    message: Yup.string().required('Required'),
  });

  const refreshRecaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset(); // Reset the reCAPTCHA
    }
  };

  const sendEmail = (event, values, actions) => {

     
    const blacklistedDomains =

    [
      '10minutemail.com', 'guerrillamail.com', 'mailinator.com', 'tempmail.org', 'throwawaymail.com', 'temp-mail.org', 
      'sharklasers.com', 'getairmail.com', 'yopmail.com', 'dispostable.com', 'jetable.org', 'mintemail.com', 
      'trashmail.com', 'fakeinbox.com', 'maildrop.cc', 'mailnesia.com', 'emailondeck.com', 'discard.email', 
      'anonbox.net', 'mailcatch.com', 'tempmailaddress.com', 'tempail.com', 'tempinbox.com', 'inboxalias.com', 
      'fake-email.cc', 'mailsac.com', 'mailnesia.org', 'mytemp.email', 'mailforspam.com', 'zippymail.info', 
      'getnada.com', 'mailnesia.info', 'emlhub.com', 'disposableinbox.com', 'moakt.com', 'mail2tor.com', 'inboxkitten.com', 
      'mintemail.net', 'yopmail.fr', 'incognitomail.org', 'getnada.net', 'fakeinbox.org', 'getnada.com', 'yopmail.net', 
      'mailinator2.com', '10mail.org', 'yopmail.org', 'mailinator.net', 'trashmailer.com', 'incognitomail.net', 
      'byebyemail.com', '10minutemail.net', 'givmail.com', 'incognitomail.com', 'byebyemail.net', 'get1mail.com', 
      '10minutemail.be', 'eml.pp.ua', 'mailinator.org', 'zhorachu.com', 'mymail-in.net', 'mailinator.in', 'spamavert.com', 
      'mytempmail.com', 'mytemp.email', 'meltmail.com', 'tempmail.de', 'tempmail.it', 'tempmail.fr', 'tempmail.ru', 
      'spamfree24.org', 'mailnesia.com', 'spamgourmet.com', 'tempmail2.net', 'mailcatch.com', 'spamex.com', 
      'tempomail.fr', 'jetable.fr.nf', 'incognitomail.com', 'tempinbox.co.uk', 'guerrillamailblock.com', 
      'spambox.us', 'wh4f.org', 'mailnesia.org', 'mohmal.com', 'spam4.me', 'deadaddress.com', 'tempemail.net', 
      'my10minutemail.com', 'spambox.me', 'tempomail.com', 'spamoff.de', 'spaml.de', 'get2mail.fr', 'trbvm.com', 
      'disposableinbox.com', 'mailinator.fr', 'incognitomail.net', 'tmpmail.net', 'guerrillamail.biz', 'binkmail.com', 
      'incognitomail.org', 'grr.la', 'mailinator.co.uk'
  ]
    

    const email = form1.current["email"].value;
    const domain = email.split('@')[1].toLowerCase();
  
    // Check if the domain is blacklisted
    if (blacklistedDomains.includes(domain)) {
      // Show an error message for blacklisted domain
      setShowModalError(false);
      alert("Domain blocked.")
      setTimeout(function () {
        setShowModalError(false);
      }, 1000);
      return;
    }
  
   
    if (verified==false) {
     alert('Recaptcha not validated');
     refreshRecaptcha();
     recaptchaRef.current.reset();
     
      return;
    }

    emailjs.sendForm('service_6cuir0f', 'contact-form', form1.current, '4Y94BulGaL_N4yMfK')
      .then(
        (result) => {
          setTimeout(function () {
            window.location.assign('/thankyou');
         
          }, 500);
          console.log(result)
      }, (error) => {
        setShowModalError(true);
      });
  };

  return (
    <div>
      {showModal ? <Modall closeModal={closeModal} /> : ''}
      {showModalError ? <Modallerror closeModal={closeModal} /> : ''}

      <section id="contact" className="contact mb-2">
        <div className="container">
          <div className="text-center" data-aos="fade-down">
            <h2 className="fw-semibold text-center">We have got your back! </h2>
            <p className="fw-semibold fs-14">
              Complete this form and we will get back to you in 24 hours.
            </p>
          </div>
          <div className="row mx-auto mt-0 mt-md-5">
            <div
              className="col-lg-8 mx-auto mt-5 mt-lg-0"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <Formik
                initialValues={{
                  name: '',
                  phone: '',
                  email: '',
                  message: '',
                  'g-recaptcha-response': '',
                }}
                validationSchema={validationSchema}
                onSubmit={sendEmail}
                innerRef={recaptchaRef}
                >

                {(formikProps) => (
                  <Form  ref={form1}  >
                    <div className="row">
                      <div className="col-md-4 form-group">
                        <label htmlFor="name" className="fw-semibold">
                          Full Name
                        </label>
                        <Field
                          className="form-control"
                          type="text"
                          name="name"
                          id="name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="col-md-4 form-group mt-3 mt-md-0">
                        <label htmlFor="phone" className="fw-semibold">
                          Phone Number
                        </label>
                        <Field name="phone">
                          {({ field }) => (
                            <PhoneInput
                              className={`contact-phone ${
                                formikProps.touched.phone && formikProps.errors.phone
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              country={'us'}
                              value={field.value}
                              inputProps={{
                                name: field.name,
                                required: true,
                              }}
                              onChange={(value) => formikProps.setFieldValue('phone', value)}
                              onBlur={field.onBlur}
                              isValid={(value, country) => {
                                return value.trim().length >= 11; // Custom validation for 10-digit phone number
                              }}
                            />
                          )}


                        </Field>
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="text-danger"
                        />


                      </div>
                      <div className="col-md-4 form-group mt-3 mt-md-0">
                        <label htmlFor="email" className="fw-semibold">
                          Email
                        </label>
                        <Field
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Your Email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="message" className="fw-semibold">
                        Message
                      </label>
                      <Field
                        as="textarea"
                        className="form-control"
                        name="message"
                        rows="5"
                        placeholder="Message"
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="my-3"></div>
                    <div className="text-center">
                      <div className="g-recaptcha d-flex justify-content-center align-items-center mt-2 mb-2">
                        <ReCAPTCHA onChange={()=>setVerified(true)} sitekey="6LfCyyEnAAAAAAznHFaBkK87hVn1RC2EvmUyJ6pE" ref={recaptchaRef}/>
                      </div>
                      <button
                        type="submit"
                        className="btn view-btn"
                       
                      >
                        {formikProps.isSubmitting ? 'Send Message' : 'Send Message'}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
