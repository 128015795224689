import React from 'react'
import Logo from "../../assets/img/logo.png"
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const Landingnavbar = () => {
  return (
    <div className='landingnav'>
    <div className='container'>
        <div className='row pt-3'>
            <div className=' col-md-7  '>
              <div className='landing-icons'>
                <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2FAccidentMate"> <Icon icon="ant-design:twitter-circle-filled" color="#fff" width="36" height="36" /> </Link>
                <Link to="https://www.youtube.com/@AccidentMATE"> <Icon className='youtube-icon' icon="ri:youtube-fill" color="white" width="24" height="24" /></Link>
              </div>
            </div>
            <div className='col-md-5 image-div'>
              <img className='landing-logo' src={Logo} alt='Accident-Mate-LOGO'></img>
            </div>
          </div>
    </div>
    </div>
  )
}

export default Landingnavbar
